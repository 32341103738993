<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>CONTACT US BANNER</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.contactUsAddModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>
        <custom-vue-table :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10"
                          class="c-cms-table d-sm-none-lg-block" ref="table" :extra-params="{ext_search}">

            <template #banner_image="{rowData}">
                <div class="container-image fl-x-cc pos-r" @click="viewFile(rowData.banner_image)">
                    <img :src="rowData.banner_image" alt="Avatar" class="c-image cursor-pointer">
                    <div class="c-overlay">
                        <div class="c-icon ml-3">
                            <i class="fa fa-eye cursor-pointer"></i>
                        </div>
                    </div>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>
        <contact-us-mobile-view ref="contactUsMobileView" :ext_search="ext_search" @viewDetails="viewDetails"
                                @setDelete="setDelete"
                                class="d-lg-none"></contact-us-mobile-view>

        <modal title="Add Contact Us Banner" ref="contactUsAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddContactUs @success="formSuccess"></AddContactUs>
        </modal>

        <modal title="Edit Contact Us Banner" ref="contactUsEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditContactUs :initial-data="editingItem" @success="formSuccess"></EditContactUs>
        </modal>

        <delete-modal ref="contactUsDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Contact Us <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddContactUs from './AddContactUs';
import EditContactUs from './EditContactUs';
import ContactUsMobileView from '@/views/cms/contact-us/contact-us/ContactUsMobileView';

export default {
    name : 'ContactUs',

    components : { ContactUsMobileView, AddContactUs, EditContactUs },

    data () {
        return {
            ext_search : '',
            listUrl    : urls.cms.contactUs.list,
            deleteUrl  : urls.cms.contactUs.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name  : '__slot:banner_image',
                    title : 'Banner Image'
                },
                {
                    name      : 'banner_text_3',
                    sortField : 'banner_text_3',
                    title     : 'Banner Text'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/contact-us-banner/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.contactUsAddModal.close();
            refs.table.refreshTable();
            refs.contactUsMobileView.loadData();

            this.editingItem = null;
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.contactUsDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.contactUsDeleteModal.close();
            this.$refs.table.refreshTable();
            this.$refs.contactUsMobileView.loadData();
        }

    }
};
</script>

<style scoped>

</style>
